import React from "react";
import Sketch from "react-p5";

const headlineSketch = (props) => {
    class Particle {
        constructor(p5, x, y){
            this.x = x > 0 ? x : p5.random(0,p5.width);
            this.y = y > 0 ? y : p5.random(0,p5.height);
            this.r = p5.random(1,50);
            this.xSpeed = p5.random(-3, 3);
            this.ySpeed = p5.random(-3, 3);
            this.lifeSpan = 100;
        }

        createParticle(p5) {
            p5.noStroke();
            p5.fill(255, 255, 255, this.lifeSpan);
            p5.circle(this.x, this.y, this.r);
        }

        moveParticle(p5) {
            if (this.x < 0 || this.x > p5.width) {
                this.xSpeed*=-1;
            }
            
            if (this.y < 0 || this.y > p5.height) {
                this.ySpeed*=-1;
            }   
            this.x += this.xSpeed;
            this.y += this.ySpeed;
        }

        updateParticle() {
            if (this.lifeSpan > 0) {
                this.lifeSpan--;
            }
        }
      
        joinParticles(p5, particles) {
            particles.forEach(element =>{
                const dis = p5.dist(this.x, this.y, element.x, element.y);
                if (dis < 100) {
                    p5.stroke('rgba(255, 255, 255, 0.5)');
                    p5.line(this.x, this.y, element.x, element.y);
                }
            });
        }
    }

    let particles = [];
    const maxParticles = 300;
    const creationInterval = 3;
    let counter = 0;

    const setup = (p5, canvasParentRef) => {
        p5.createCanvas(props.width, props.height).parent(canvasParentRef);
    };
      
    const draw = (p5) => {
        p5.background(20, 99, 94);
        if (particles.length < maxParticles && counter >= creationInterval) {
            if (p5.mouseX > 0 && p5.mouseX < p5.width && p5.mouseY > 0 && p5.mouseY < p5.height) {
                particles.push(new Particle(p5, p5.mouseX, p5.mouseY));
            } else {
                particles.push(new Particle(p5, p5.random() * p5.width, p5.random() * p5.height));
            }
            counter = 0;
        }
        for (let i = particles.length - 1; i >= 0; i--) {
            //particles[i].createParticle(p5);
            particles[i].moveParticle(p5);
            particles[i].joinParticles(p5, particles.slice(i));
            particles[i].updateParticle();
            if (particles[i].lifeSpan <= 0) {
                particles.splice(i, 1);
            }
        }
        counter++;
    };

    return <Sketch setup={setup} draw={draw} />;
};

export default headlineSketch;