import React, { Component } from 'react'

import styles from './FilterButton.module.css';

class FilterButton extends Component {
    render() {
        let classes = [styles.FilterButton];
        classes.push(this.props.selected ? styles.Selected : styles.Default)
        return (
            <span className={classes.join(" ")} onClick={this.props.clicked}>{this.props.name}</span>
        );
    }
}

export default FilterButton;