import React, { Component } from 'react';

import styles from './Headline.module.css';

import Container from 'react-bootstrap/Container';
import HeadlineSketch from './HeadlineSketch/HeadlineSketch3';

class Headline extends Component {
    state = {
        width: 1,
        height: 1
    }

    componentDidMount() {
        this.setState({width: this.divElement.clientWidth, height: this.divElement.clientHeight});
    }

    render() {
        return (
            <section className={styles.Headline}>
                <div className={styles.Fill}
                    ref={(divElement) => {this.divElement = divElement}}
                >
                    <HeadlineSketch width={this.state.width} height={this.state.height}/>
                    <Container className={styles.Content}>
                        <div className={styles.Title}>Hi! I'm <span className={styles.Name}>Brittany</span>,</div>
                        <div className={styles.Subtitle}>an app and game developer</div>
                    </Container>
                    <div className={styles.Arrow}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </section>
        );
    }
}

export default Headline;