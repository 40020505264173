import React from 'react';

import styles from './About.module.css';

import { Container, Row, Col } from 'react-bootstrap';
import Profile from './Profile/Profile';
import Skills from './Skills/Skills';

const about = () => (
    <section className={styles.About}>
        <Container>
            <Row className="align-items-center">
                <Col lg>
                    <Profile />
                </Col>
                <Col lg>
                    <Skills />
                </Col>
            </Row>
        </Container>
    </section>
);

export default about;