import React, { Component } from 'react';

import styles from './Profile.module.css';
import ProfileImage from './../../../assets/images/profile.jpg';

import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

class Profile extends Component {
    title = "Hi, I'm Brittany!";

    render() {
        return (
            <div className={styles.Profile}>
                <ScrollAnimation animateOnce animateIn="animate__fadeInLeft">
                    <img src={ProfileImage} alt="Brittany Pinder"/>
                </ScrollAnimation>
                <ScrollAnimation animateOnce animateIn="animate__fadeInLeft">
                    <h1 className={styles.Title}>
                        {this.title.split("").map((letter, index) => (
                            <span className={styles.Letter} key={index}>{letter}</span>
                        ))}
                    </h1>
                </ScrollAnimation>
                <ScrollAnimation animateOnce animateIn="animate__fadeInLeft">
                    <p>I am the creator of <a href="https://www.clockwisetime.app" target="_blank" rel="noopener noreferrer">Clockwise</a> and a former developer on <a href="https://www.king.com/game/candycrush" target="_blank" rel="noopener noreferrer">Candy Crush Saga</a> <span role="img" aria-label="lollipop">🍭</span>. Whether it’s programming a video game, developing an app or creating a website, I am passionate about creating beautiful and interactive experiences with code. Away from the screen I enjoy traveling, playing music and spending time with my family.</p>
                </ScrollAnimation>
            </div>
        )
    }
}

export default Profile;