import React from 'react';

import styles from './ProjectCard.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';

const projectCard = (props) => (
    <div className={styles.ProjectCard}>
        <div className={styles.Image}>
            <img src={require("../../../" + props.project.image)} alt={props.project.name}/>
        </div>
        <div className={styles.Info} onClick={() => props.showModal(props.project)}>
            <div className={styles.Description}>
                <div className={styles.Title}>{props.project.name}</div>
                <div className={styles.Subtitle}>{props.project.type}</div>
                <FontAwesomeIcon icon={faSearchPlus} className={styles.Icon}/>
            </div>
        </div>
    </div>
);

export default projectCard;