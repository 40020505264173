import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './App.module.css';

import Headline from './components/Headline/Headline';
import About from './components/About/About';
import Portfolio from './components/Portfolio/Portfolio';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className={styles.App}>
      <Headline />
      <About />
      <Portfolio />
      <Footer/>
    </div>
  );
}

export default App;
