import React from "react";
import Sketch from "react-p5";

const headlineSketch = (props) => {
    const squareSize = 20;

    const setup = (p5, canvasParentRef) => {
        p5.createCanvas(props.width, props.height).parent(canvasParentRef);
        p5.frameRate(10);
        p5.noStroke();
    };

    const draw = (p5) => {
        for (let x = 0; x < p5.width; x += squareSize) {
            for (let y = 0; y < p5.height; y += squareSize) {
                if (p5.random() < 0.5) {
                    p5.fill(20, 99, 94);
                } else {
                    p5.fill(255, 255, 255, p5.random() * 30);
                }
                p5.rect(x, y, squareSize, squareSize);
            }
        }
    };

    return <Sketch setup={setup} draw={draw} />;
};

export default headlineSketch;