import React, { Component } from 'react';

import _ from 'lodash/array';

import styles from './Portfolio.module.css';

import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

import { Container, Row, Col } from 'react-bootstrap';
import FilterButton from './FilterButton/FilterButton';
import ProjectCard from './ProjectCard/ProjectCard';
import Modal from './Modal/Modal';

import projectData from './../../assets/data/projects.json';

class Portfolio extends Component {
    state = {
        showModal: false,
        filteredProjects: projectData,
        selectedProject: null,
        selectedFilter: "all"
    }

    showModal = (project) => {
        document.body.classList.add(styles.NoScroll);
        this.setState({showModal: true, selectedProject: project});
    }

    hideModal = (event) => {
        document.body.classList.remove(styles.NoScroll);
        this.setState({showModal: false});
    }

    onFilterClicked = (category) => {
        let filteredProjects = projectData;
        if (category !== "all") {
            filteredProjects = projectData.filter(project => project.category === category);
        }
        this.setState({filteredProjects: filteredProjects, selectedFilter: category});
    }

    render() {
        const modal = this.state.showModal ? <Modal hideModal={this.hideModal} project={this.state.selectedProject}/> : null;
        return (
            <section className={styles.Portfolio}>
                {modal}
                <Container className={styles.Projects}>
                    {_.chunk(this.state.filteredProjects, 3).map((projectGroup, groupIndex) => (
                        <Row key={groupIndex}>
                            {projectGroup.length === 1 ? <Col></Col> : null}
                            {projectGroup.map((project, index) => (
                                <Col lg key={project.name}>
                                    <ScrollAnimation animateOnce animateIn="animate__fadeInUp" delay={index * 100}>
                                        <ProjectCard
                                            project={project}
                                            showModal={this.showModal}
                                        />
                                    </ScrollAnimation>
                                </Col>
                            ))}
                            {projectGroup.length < 3 ? <Col></Col> : null}
                        </Row>
                    ))}
                </Container>
            </section>
        );
    }
}

export default Portfolio;