import React from 'react';

import styles from './Skills.module.css';

import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faWrench } from '@fortawesome/free-solid-svg-icons';

const skills = ()  => {
    const languages = [
        "C++",
        "Swift",
        "C#",
        "Python",
        "JavaScript",
        "HTML",
        "CSS"
    ];

    const tools = [
        "SwiftUI",
        "UIKit",
        "xCode",
        "Visual Studio",
        "Unity",
        "React",
        "Git"
    ];

    return (
        <div className={styles.Skills}>
            <ScrollAnimation animateOnce animateIn="animate__fadeInRight" className={styles.SkillSection}>
                <FontAwesomeIcon icon={faCode} className={styles.Icon}/>
                <h1 className={styles.SkillTitle}>languages</h1>
                {languages.map((language, index) => (
                    <ScrollAnimation animateOnce animateIn="animate__fadeInRight" className={styles.Item} key={index}>
                        <span>{language.toLowerCase()}</span>
                    </ScrollAnimation>
                ))}
            </ScrollAnimation>
            <ScrollAnimation animateOnce animateIn="animate__fadeInRight" className={styles.SkillSection}>
                <FontAwesomeIcon icon={faWrench} className={styles.Icon}/>
                <h1 className={styles.SkillTitle}>tools & frameworks</h1>
                {tools.map((tool, index) => (
                    <ScrollAnimation animateOnce animateIn="animate__fadeInRight" className={styles.Item} key={index}>
                        <span>{tool.toLowerCase()}</span>
                    </ScrollAnimation>
                ))}
            </ScrollAnimation>
        </div>
    );
}

export default skills;
