import React from "react";
import Sketch from "react-p5";

const headlineSketch = (props) => {
    const numParticles = 50;
    const speed = 0.01;
    const minRadius = (props.width + props.height) * 0.04;
    const maxRadius = (props.width + props.height) * 0.07;
    const particleSpeed = 1;
    const pointSpeed = 3;

    class Point {
        constructor(p5, x, y, angle, speed) {
            this.pos = p5.createVector(x, y);
            this.angle = angle;
            this.speed = speed;
        }
    }

    class TriangleParticle {
        constructor(p5, posX, posY, radius, colors) {
            this.pos = p5.createVector(posX, posY);
            this.velocity = p5.createVector(p5.random(-particleSpeed, particleSpeed), p5.random(-particleSpeed, particleSpeed));
            this.radius = radius;
            this.radiusSpeed = p5.random(0.1, 0.2);
            this.colors = colors;

            this.points = [];
            for (let i = 0; i < 5; ++i) {
                let speed = p5.random(-pointSpeed, pointSpeed);
                let angle = Math.random() * Math.PI * 2;
                let x = this.pos.x + Math.cos(angle) * this.radius / 2;
                let y = this.pos.y + Math.sin(angle) * this.radius / 2;
                this.points.push(new Point(p5, x, y, angle, speed));
            }
        }

        updatePosition(p5) {
            if (this.pos.x < 0 || this.pos.x > p5.width) {
                this.velocity.x *= -1;
            }
            if (this.pos.y < 0 || this.pos.y > p5.height) {
                this.velocity.y *= -1;
            }
            this.pos.add(this.velocity);
        }

        updatePoints(p5) {
            this.radius += this.radiusSpeed;
            if (this.radiusSpeed > maxRadius || this.radiusSpeed < minRadius) {
                this.radiusSpeed *= -1;
            }
            for (let i = 0; i < this.points.length; ++i) {
                this.points[i].angle += this.points[i].speed * speed;
                this.points[i].pos.x = this.pos.x + Math.cos(this.points[i].angle) * this.radius / 2;
                this.points[i].pos.y = this.pos.y + Math.sin(this.points[i].angle) * this.radius / 2;
            }
        }

        updateParticle(p5) {
            this.updatePosition(p5);
            this.updatePoints(p5);
        }

        drawParticle(p5) {
            p5.stroke(27, 43, 79);

            p5.fill(this.colors[0]);
            p5.triangle(this.points[0].pos.x, this.points[0].pos.y, this.points[1].pos.x, this.points[1].pos.y, this.points[2].pos.x, this.points[2].pos.y);

            p5.fill(this.colors[1]);
            p5.triangle(this.points[1].pos.x, this.points[1].pos.y, this.points[2].pos.x, this.points[2].pos.y, this.points[3].pos.x, this.points[3].pos.y);

            p5.fill(this.colors[2]);
            p5.triangle(this.points[2].pos.x, this.points[2].pos.y, this.points[3].pos.x, this.points[3].pos.y, this.points[4].pos.x, this.points[4].pos.y);
        }
    }

    let particles = [];

    const setup = (p5, canvasParentRef) => {
        p5.createCanvas(props.width, props.height).parent(canvasParentRef);

        const color1 = p5.color(65, 158, 209, 75);
        const color2 = p5.color(243, 148, 255, 75);
        const color3 = p5.color(255, 255, 255, 75);
        let colors = [color1, color2, color3];

        for (let i = 0; i < numParticles; ++i) {
            particles.push(new TriangleParticle(p5, Math.random() * p5.width, Math.random() * p5.height, minRadius + p5.random() * (maxRadius - minRadius), colors));
        }
    };

    const draw = (p5) => {
        p5.background(27, 43, 79);
        for (let i = 0; i < particles.length; ++i) {
            particles[i].updateParticle(p5);
            particles[i].drawParticle(p5);
        }
    };

    const windowResized = (p5) => {
        p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
    }

    return <Sketch setup={setup} draw={draw} windowResized={windowResized}/>;
};

export default headlineSketch;