import React, { Component } from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import Carousel from './Carousel/Carousel';

import styles from './Modal.module.css';

class Modal extends Component {
    consumeClick = (event) => {
        event.stopPropagation();
    }

    render() {
        return (
            <React.Fragment>
                <div className={styles.Backdrop} onClick={this.props.hideModal}></div>
                <div className={styles.Content} onClick={this.props.hideModal}>
                    <FontAwesomeIcon icon={faTimes} className={styles.CloseButton}/>
                    <Container>
                        <Row>
                            <Col xl="8">
                                <Carousel clicked={this.consumeClick} slides={this.props.project.slides}/>
                            </Col>
                            <Col className={styles.Info}>
                                <div className={styles.Title}>{this.props.project.name}</div>
                                <div className={styles.Time}>{this.props.project.time}</div>
                                <div className={styles.Tags}>{this.props.project.tech.map((item, itemIndex) => (
                                    <div className={styles.Tag} key={itemIndex}>{item}</div>
                                ))}</div>
                                <div className={styles.Description}>
                                    {this.props.project.description.map((paragraph, paragraphIndex) => (
                                        <p key={paragraphIndex}>{paragraph}</p>
                                    ))}
                                </div>
                                {this.props.project.link ?
                                    <a href={this.props.project.link.url} target="_blank" rel="noopener noreferrer" className={styles.LinkButton} onClick={this.consumeClick}>
                                        {this.props.project.link.text}
                                    </a>
                                : null}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Modal;