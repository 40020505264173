import React, { Component } from 'react';

import styles from './Carousel.module.css';

class Carousel extends Component {
    state = {
        slideIndex: 0
    }

    onSlideClicked = (index) => {
        this.setState({slideIndex: index});
    }

    render() {
        const selectedSlide = this.props.slides[this.state.slideIndex];
        const selectedContent = selectedSlide.type === "image" ?
            <img
                src={require("../../../../" + selectedSlide.image)}
                alt={selectedSlide.title}
            />
            :
            <div className={styles.VideoContainer}>
                <iframe
                    className={styles.Video}
                    src={selectedSlide.video}
                    frameBorder="0"
                    title={selectedSlide.title}
                    allowFullScreen>
                </iframe>
            </div>
        const selectedCaption = selectedSlide.caption ? <div className={styles.Caption}>{selectedSlide.caption}</div> : ""

        return (
            <div className={styles.Carousel} onClick={this.props.clicked} >
                <div className={styles.View}>
                    {selectedContent}
                    {selectedCaption}
                </div>
                {this.props.slides.length > 1 ?
                    <div className={styles.Pagination}>
                        {this.props.slides.map((slide, index) => (
                            <img
                                id={index === this.state.slideIndex ? styles.SelectedSlide : null}
                                key={index}
                                src={require("../../../../" + slide.thumbnail)}
                                alt="slide"
                                onClick={() => this.onSlideClicked(index)}
                            />
                        ))}
                    </div>
                : ""}
            </div>
        )
    }
}

export default Carousel;