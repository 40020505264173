import React from 'react';

import styles from './Footer.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn, faGithub, fa500px, faSoundcloud, faTwitter } from '@fortawesome/free-brands-svg-icons';

const footer = () => (
    <section className={styles.Footer}>
        <div className={styles.Links}>
            <a href="mailto:brittanypetrova.dev@gmail.com" target="_blank" rel="noopener noreferrer" className={styles.IconLink}>
                <FontAwesomeIcon className={styles.Icon} icon={faEnvelope} />
            </a>
            <a href="https://www.linkedin.com/in/brittanypinder/" target="_blank" rel="noopener noreferrer" className={styles.IconLink}>
                <FontAwesomeIcon className={styles.Icon} icon={faLinkedinIn} />
            </a>
            <a href="https://github.com/brittpinder" target="_blank" rel="noopener noreferrer" className={styles.IconLink}>
                <FontAwesomeIcon className={styles.Icon} icon={faGithub} />
            </a>
            <a href="https://twitter.com/brittpetrova" target="_blank" rel="noopener noreferrer" className={styles.IconLink}>
                <FontAwesomeIcon className={styles.Icon} icon={faTwitter} />
            </a>

            {/* <a href="https://500px.com/brittpinder" target="_blank" rel="noopener noreferrer" className={styles.IconLink}>
                <FontAwesomeIcon className={styles.Icon} icon={fa500px} />
            </a>

            <a href="https://soundcloud.com/brittany-pinder" target="_blank" rel="noopener noreferrer" className={styles.IconLink}>
                <FontAwesomeIcon className={styles.Icon} icon={faSoundcloud} />
            </a> */}

        </div>
        <div>
            Copyright © 2024 Brittany Petrova
        </div>
    </section>
);

export default footer;